import React from "react"

import SEO from "../components/seo"
import "../assets/css/all.css"

const ThanksPage = () => {
  return (
    <>
      <SEO title="Tuteur à l'Heure " />
      <div
        data-server-rendered="true"
        id="app"
        className="layout landing no-progressbar step-step-cible auto-continue"
      >
        <div className="steps-zone">
          <div className="steps-content">
            <h1>
              Merci, nous vous recontacterons sous peu pour mettre en place des
              cours adaptés dans les prochains jours.
            </h1>
          </div>
        </div>
      </div>
    </>
  )
}

export default ThanksPage
